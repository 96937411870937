<template>
  <div class="container" v-if="step == 1">
    <div id="form">
      <div>
        {{ $gettext('Per inviare un messaggio all\'utente compila il form e premi sul bottone invia messaggio.') }}
      </div>
      <custom-input
        label="titolo"
        :value="titolo"
        type="text"
        @input="(val) => (titolo = val)"
      />
      <custom-textarea
        label="messaggio"
        name="messaggio"
        :value="messaggio"
        @input="(val) => (messaggio = val)"
      ></custom-textarea>
      <div class="buttons" v-if="!sending">
        <button @click="send" :disabled="noSend">Invia messaggio</button>
      </div>
      <div class="sending" v-if="sending">Invio in corso...</div>
    </div>
  </div>
  <div class="container" v-if="step == 2">
    <h2>{{ $gettext('Il tuo messaggio è stato preso in carico per essere inoltrato') }}</h2>
    <div class="buttons">
      <button @click="back" :disabled="noSend">
        {{ $gettext('Torna alla scheda dell\'utente') }}
      </button>
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
import customTextarea from '@/components/shared/custom-textarea';

export default {
  name: 'sendMessage',
  components: { customTextarea, customInput },
  data() {
    return {
      titolo: '',
      messaggio: '',
      noSend: true,
      sending: false,
      step: 1,
      sendMsg: '',
      to_user: '',
    };
  },
  methods: {
    async send() {
      this.sendMsg = '';
      this.sending = true;
      const params = {
        title: this.titolo,
        content: this.messaggio,
        acf: { to_user: this.to_user },
      };
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      try {
        let send = this.axios.post(this.api.messaggio, params, config);
        this.sending = false;
        send.then((response) => {
          this.step = 2;
        });
      } catch (error) {
        this.sending = false;
        console.log(error.response);
        this.sendMsg =
          $gettext('In questo momento non è stato possibile inviare il tuo messaggio. Per favore prova più tardi.');
      }
    },
    back() {
      this.$router.back();
    },
  },
  computed: {
    noSend() {
      if (
        this.titolo.trim() == '' ||
        this.messaggio.trim() == '' ||
        this.messaggio.trim().length < 20 ||
        this.titolo.trim().length < 10
      )
        return true;
      return false;
    },
  },
  created() {
    this.to_user = this.$route.params.to_user;
  },
};
</script>

<style scoped>
#form {
  width: 90%;
  margin: 30px auto 0;
}
</style>
